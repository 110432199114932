<template>
  <v-dialog v-model="visible" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="green"
        depressed
        icon
        small
        @click="fetchPaymentInfo(addressId)"
      >
        <v-icon small>mdi-credit-card</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ siteName }}
      </v-card-title>

      <v-card-subtitle class="pt-1">
        Payment Information
      </v-card-subtitle>

      <LoadingSpinner v-if="loadingPaymentInfo" />

      <div v-else class="pb-5 px-5">
        <EmptyCard
          v-if="!paymentInfo"
          content="No Payment Information"
          hide-button
        />
        <div v-else>
          <v-row>
            <v-col cols="6">
              <DataValue
                label="Payment Method"
                :value="paymentMethod"
                icon="currency-gbp"
                icon-color="deep-purple lighten-2 cursor-pointer"
              />
            </v-col>
            <v-col cols="6" v-if="isDirectDebit">
              <DataValue
                label="Bank Name"
                :value="paymentInfo.bankName"
                icon="bank"
                icon-color="green lighten-2"
              />
            </v-col>
          </v-row>
          <v-row v-if="isDirectDebit">
            <v-col cols="6">
              <DataValue
                label="Account Name"
                :value="paymentInfo.accountName"
                icon="account"
                icon-color="blue lighten-2 cursor-pointer"
              />
            </v-col>
            <v-col cols="6">
              <DataValue
                label="Sort Code"
                :value="paymentInfo.sortCode"
                icon="pound"
                icon-color="deep-orange lighten-2"
              />
            </v-col>
          </v-row>
          <v-row v-if="isDirectDebit">
            <v-col cols="6">
              <DataValue
                label="Account Number"
                :value="paymentInfo.accountNumber"
                icon="numeric"
                icon-color="indigo lighten-2"
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="visible = false">
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue" text @click="openFormAndClose">
          Edit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import DataValue from "@/components/data-value"
import LoadingSpinner from "@/components/loading-spinner"
import EmptyCard from "@/components/empty-card"

export default {
  components: {
    DataValue,
    LoadingSpinner,
    EmptyCard
  },
  props: {
    addressId: {
      type: String,
      default: ""
    },
    siteName: {
      type: String,
      default: ""
    }
  },
  data: () => ({ visible: false }),
  computed: {
    ...mapGetters("addresses", ["paymentInfo", "loadingPaymentInfo"]),
    isDirectDebit() {
      const method = this.paymentInfo.typeId
      return method === 1
    },
    paymentMethod() {
      return this.isDirectDebit ? "Direct Debit" : "Cash & Cheque"
    }
  },
  methods: {
    ...mapActions("addresses", ["fetchPaymentInfo"]),
    ...mapActions("ui", ["openForm"]),
    openFormAndClose() {
      this.visible = false
      this.openForm({ formName: "payment-info", edit: true })
    },
  }
}
</script>
